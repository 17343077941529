/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.12,
* Autoprefixer: v10.4.4
* Browsers: last 4 version
*/

.App {
    background-color: black;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem 0;
}

.container {
    /* size between the containers */
    width: 100%;
    height: 33vw;
    /* margin:0.5rem 0; */
    overflow: hidden;
    padding: 6px 0;
}

.carousel,
.carousel2 {
    /* background-color: blueviolet; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(30vw * 10);
    height: 41vw;
    -webkit-animation: scrollLeft 15s forwards infinite 1s;
    animation: scrollLeft 15s forwards infinite 1s;
}

.carousel2 {
    -webkit-animation: scrollRight 15s forwards infinite 1s;
    animation: scrollRight 15s forwards infinite 1s;
}

.carousel:hover,
.carousel2:hover {
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

@-webkit-keyframes scrollLeft {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    8% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    16% {
        -webkit-transform: translateX(-20vw);
        transform: translateX(-20vw);
    }
    24% {
        -webkit-transform: translateX(-20vw);
        transform: translateX(-20vw);
    }
    32% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    40% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    48% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    56% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    64% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    72% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    80% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
    88% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
}

@keyframes scrollLeft {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    8% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    16% {
        -webkit-transform: translateX(-20vw);
        transform: translateX(-20vw);
    }
    24% {
        -webkit-transform: translateX(-20vw);
        transform: translateX(-20vw);
    }
    32% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    40% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    48% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    56% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    64% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    72% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    80% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
    88% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
}

@-webkit-keyframes scrollLeftMobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    8% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    16% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    24% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    32% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    40% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    48% {
        -webkit-transform: translateX(-298vw);
        transform: translateX(-298vw);
    }
    56% {
        -webkit-transform: translateX(-298vw);
        transform: translateX(-298vw);
    }
    64% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    72% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    80% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
    88% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
}

@keyframes scrollLeftMobile {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    8% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    16% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    24% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    32% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    40% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    48% {
        -webkit-transform: translateX(-298vw);
        transform: translateX(-298vw);
    }
    56% {
        -webkit-transform: translateX(-298vw);
        transform: translateX(-298vw);
    }
    64% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    72% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    80% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
    88% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
}
@-webkit-keyframes scrollRight {
    0% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
    8% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
    16% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    24% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    32% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    40% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    48% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    56% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    64% {
        -webkit-transform: translateX(-30vw);
        transform: translateX(-30vw);
    }
    72% {
        -webkit-transform: translateX(-30vw);
        transform: translateX(-30vw);
    }
    80% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    88% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-155vw);
        transform: translateX(-155vw);
    }
}
@keyframes scrollRight {
    0% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
    8% {
        -webkit-transform: translateX(calc(-155vw));
        transform: translateX(calc(-155vw));
    }
    16% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    24% {
        -webkit-transform: translateX(calc(-120vw));
        transform: translateX(calc(-120vw));
    }
    32% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    40% {
        -webkit-transform: translateX(calc(-90vw));
        transform: translateX(calc(-90vw));
    }
    48% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    56% {
        -webkit-transform: translateX(-60vw);
        transform: translateX(-60vw);
    }
    64% {
        -webkit-transform: translateX(-30vw);
        transform: translateX(-30vw);
    }
    72% {
        -webkit-transform: translateX(-30vw);
        transform: translateX(-30vw);
    }
    80% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    88% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-155vw);
        transform: translateX(-155vw);
    }
}
@-webkit-keyframes scrollRightMobile {
    0% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
    8% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
    16% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    24% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    32% {
        -webkit-transform: translateX(calc(-298vw));
        transform: translateX(calc(-298vw));
    }
    40% {
        -webkit-transform: translateX(calc(-298vw));
        transform: translateX(calc(-298vw));
    }
    48% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    56% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    64% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    72% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    80% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    88% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-496vw);
        transform: translateX(-496vw);
    }
}
@keyframes scrollRightMobile {
    0% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
    8% {
        -webkit-transform: translateX(calc(-496vw));
        transform: translateX(calc(-496vw));
    }
    16% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    24% {
        -webkit-transform: translateX(calc(-397vw));
        transform: translateX(calc(-397vw));
    }
    32% {
        -webkit-transform: translateX(calc(-298vw));
        transform: translateX(calc(-298vw));
    }
    40% {
        -webkit-transform: translateX(calc(-298vw));
        transform: translateX(calc(-298vw));
    }
    48% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    56% {
        -webkit-transform: translateX(-198vw);
        transform: translateX(-198vw);
    }
    64% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    72% {
        -webkit-transform: translateX(-98vw);
        transform: translateX(-98vw);
    }
    80% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    88% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-496vw);
        transform: translateX(-496vw);
    }
}

.item {
    width: 42vw;
    height: 32.5vw;
    border: 1px solid #8efb62;
    -webkit-box-shadow: 0 0px 10px #8efb62;
    box-shadow: 0 0px 10px #8efb62;
    margin: 0px 1.2rem;
    padding: 2 0rem; 
    border-radius: 8px !important;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    overflow: hidden !important;
    border: 4px solid #8efb62;
}
.item:hover {
    border: 5px solid #48f503;
}
.item > * {
    border-radius: 8px !important;
}

@media only screen and (max-width: 768px) {
    .container {
        height: 94vw;
    }
    .carousel {
        -webkit-animation: scrollLeftMobile 15s forwards infinite 1s;
        animation: scrollLeftMobile 15s forwards infinite 1s;
    }
    .carousel2 {
        -webkit-animation: scrollRightMobile 15s forwards infinite 1s;
        animation: scrollRightMobile 15s forwards infinite 1s;
    }
    .carousel,
    .carousel2 {
        width: calc(99vw * 10);
        height: 100%;
    }
    .carousel:hover,
    .carousel2:hover {
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .item {
        width: 120vw;
        height: 90vw;
        margin: 0 3rem;
    }
}

.wistia_popover_overlay {
    background-color: rgba(0, 0, 0, 1) !important;
}
